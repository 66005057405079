import React from "react";
import {
  SiteNavigationElementSchema,
  LocalBusinessSchema,
  MessageDisplay,
  SiteNavigation,
  HookComponentProvider,
  HoursOfOperation,
  LocationsContext,
  RecaptchaProvider,
  EmailCapture,
  SocialMedia,
  Location,
  Phone,
  Email,
  Map,
  SiteMap,
  CopyrightLabel,
  OutboundLink,
  FishermanBanner,
} from "@bluefin/components";
import { Header, Grid } from "semantic-ui-react";
import { useContext } from "react";
import { graphql, StaticQuery } from "gatsby";

import {
  getComponentFiles,
  filterRegularLinks,
  filterButtonLinks,
} from "../utils/utils";
import "semantic-ui-less/semantic.less";
import "../style/WebsiteLayout.css";

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allFishermanBusinessWebsitePage(
          sort: { order: ASC, fields: navigationPriority }
        ) {
          nodes {
            slug
            description
            title
            pageType
            url
            navigationPriority
            groupName
            props
          }
        }
        fishermanBusiness {
          name
          logo
          menu {
            schedules {
              availableDays
              categories
              _id
              name
            }
            categories {
              items
              name
              _id
            }
            items {
              modifierSets
              description
              _id
              name
              variations {
                _id
                menuItem
                name
                order
                price
              }
            }
            modifierSets {
              _id
              minAllowed
              modifiers
              name
            }
            modifiers {
              _id
              name
              price
            }
          }
          primaryLocation {
            timezone
          }
          gatsbyLogo {
            childImageSharp {
              gatsbyImageData(layout: FIXED, height: 60)
            }
          }
          _id
          type
          socialMedia {
            link
            type
          }
        }
        allFishermanBusinessCategory {
          nodes {
            category
            subCategory
          }
        }
        allFishermanBusinessLocation {
          nodes {
            street
            city
            state
            zipCode
            country
            email
            phoneNumber
            name
            hours {
              open
              close
              day
            }
          }
        }
        fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
          components {
            fastId
            componentIdentifier
            order
            files {
              file
            }
          }
        }
        fishermanWebsiteComponent(
          layout: { name: { eq: "@" } }
          fastId: { eq: "MessageDisplay" }
        ) {
          data {
            subject
            modalDelay
            bannerBackgroundColor
          }
        }
      }
    `}
    render={(data) => {
      const {
        allFishermanBusinessWebsitePage,
        fishermanBusiness,
        allFishermanBusinessCategory,
        allFishermanBusinessLocation,
        fishermanBusinessWebsitePage,
        fishermanWebsiteComponent,
      } = data;

      return (
        <React.Fragment>
          <SiteNavigationElementSchema
            pages={allFishermanBusinessWebsitePage.nodes}
            siteUrl={"https://pokeloungeweb.com"}
          />
          <LocalBusinessSchema
            name={fishermanBusiness.name}
            categories={allFishermanBusinessCategory.nodes}
            url={"https://pokeloungeweb.com"}
            logo={fishermanBusiness.logo}
            address={allFishermanBusinessLocation.nodes}
            menu={fishermanBusiness.menu}
            images={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              numToSelect: 1,
            })}
          />
          <div className={"site-container"}>
            <div>
              <MessageDisplay
                subject={fishermanWebsiteComponent.data.subject}
                modalHeader={
                  <div>{fishermanWebsiteComponent.data.subject}</div>
                }
                businessName={fishermanBusiness.name}
                modalDelay={fishermanWebsiteComponent.data.modalDelay}
                bannerBackgroundColor={
                  fishermanWebsiteComponent.data.bannerBackgroundColor
                }
                timezone={fishermanBusiness.primaryLocation.timezone}
              />
              <SiteNavigation fullScreen={true}>
                <SiteNavigation.Menu position={"left"}>
                  <SiteNavigation.Logo
                    title={<Header as={"h3"}>{fishermanBusiness.name}</Header>}
                    src={fishermanBusiness.gatsbyLogo.childImageSharp}
                    position={"left"}
                  />
                </SiteNavigation.Menu>
                <SiteNavigation.Menu
                  className={"site-navigation-menu centered"}
                >
                  <SiteNavigation.Links
                    links={filterRegularLinks({
                      links: allFishermanBusinessWebsitePage.nodes,
                    })}
                  />
                </SiteNavigation.Menu>
                <SiteNavigation.Menu position={"right"}>
                  <SiteNavigation.Item>
                    <HookComponentProvider
                      hook={useContext}
                      component={
                        <HoursOfOperation
                          displayOption={"dailyWithPopup"}
                          displayOpenClosedStatus={true}
                        />
                      }
                      hookToPropsMap={{
                        hours: "activeLocation.hours",
                        timezone: "activeLocation.timezone",
                      }}
                      hookParams={[LocationsContext]}
                      onlyRenderOnClientSide={true}
                    />
                  </SiteNavigation.Item>
                  <SiteNavigation.Links
                    links={filterButtonLinks({
                      links: allFishermanBusinessWebsitePage.nodes,
                    })}
                  />
                </SiteNavigation.Menu>
              </SiteNavigation>
            </div>
            <RecaptchaProvider>
              <div className={"page-container"}>{children}</div>
              <footer>
                <Grid stackable={true}>
                  <Grid.Row>
                    <Grid.Column width={10}>
                      <Grid stackable={true}>
                        <Grid.Row columns={3}>
                          <Grid.Column>
                            <HookComponentProvider
                              hook={useContext}
                              component={
                                <EmailCapture
                                  businessId={fishermanBusiness._id}
                                  businessType={fishermanBusiness.type}
                                  type={"footer"}
                                />
                              }
                              hookToPropsMap={{
                                locationId: "activeLocation.id",
                              }}
                              hookParams={[LocationsContext]}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <SocialMedia
                              socialMediaValues={fishermanBusiness.socialMedia}
                              buttonType={"rect"}
                              centerHeader={false}
                              colors={"secondary"}
                              groupButtons={false}
                              groupVertically={false}
                              inverted={true}
                              labelPosition={"free"}
                              showLabels={false}
                            />
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={3}>
                          <Grid.Column>
                            <HookComponentProvider
                              hook={useContext}
                              component={<Location businessName={"Address"} />}
                              hookToPropsMap={{
                                "address.street": "activeLocation.street",
                                "address.city": "activeLocation.city",
                                "address.state": "activeLocation.state",
                                "address.zip": "activeLocation.zipCode",
                              }}
                              requiredProps={[
                                [
                                  "address.street",
                                  "address.city",
                                  "address.state",
                                  "address.zipCode",
                                ],
                              ]}
                              hookParams={[LocationsContext]}
                              onlyRenderOnClientSide={true}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <address>
                              <HookComponentProvider
                                hook={useContext}
                                component={
                                  <div>
                                    <strong>Contact</strong>
                                  </div>
                                }
                                hookToPropsMap={{
                                  phone: "activeLocation.phoneNumber",
                                  email: "activeLocation.email",
                                }}
                                requiredProps={[["phone", "email"]]}
                                hookParams={[LocationsContext]}
                              />
                              <HookComponentProvider
                                hook={useContext}
                                component={Phone}
                                hookToPropsMap={{
                                  phone: "activeLocation.phoneNumber",
                                }}
                                hookParams={[LocationsContext]}
                                onlyRenderOnClientSide={true}
                              />
                              <HookComponentProvider
                                hook={useContext}
                                component={Email}
                                hookToPropsMap={{
                                  email: "activeLocation.email",
                                }}
                                hookParams={[LocationsContext]}
                                onlyRenderOnClientSide={true}
                              />
                            </address>
                          </Grid.Column>
                          <Grid.Column>
                            <strong>Hours of Operation</strong>
                            <HookComponentProvider
                              hook={useContext}
                              component={
                                <HoursOfOperation
                                  displayOption={"dailyWithPopup"}
                                  displayOpenClosedStatus={true}
                                  startWithToday={false}
                                  position={"top left"}
                                />
                              }
                              hookToPropsMap={{
                                hours: "activeLocation.hours",
                                timezone: "activeLocation.timezone",
                              }}
                              hookParams={[LocationsContext]}
                              onlyRenderOnClientSide={true}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <HookComponentProvider
                        hook={useContext}
                        component={Map}
                        hookToPropsMap={{
                          "address.street": "activeLocation.street",
                          "address.city": "activeLocation.city",
                          "address.state": "activeLocation.state",
                          "address.zip": "activeLocation.zipCode",
                        }}
                        hookParams={[LocationsContext]}
                        onlyRenderOnClientSide={true}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column textAlign={"center"}>
                      <SiteMap
                        links={allFishermanBusinessWebsitePage.nodes}
                        horizontal={true}
                        bulleted={false}
                      />
                      <CopyrightLabel
                        company={fishermanBusiness.name}
                        phrase={"All Rights Reserved"}
                        privacyPolicyLink={
                          <OutboundLink
                            to={
                              "https://fisherman-static.s3.amazonaws.com/public/__fisherman/Client+Website+Privacy+Policy.pdf"
                            }
                            label={"Privacy Policy"}
                            eventLabel={"copyright-privacy-policy"}
                          />
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </footer>
            </RecaptchaProvider>
          </div>
          <FishermanBanner />
        </React.Fragment>
      );
    }}
  />
);
